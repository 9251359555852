<template>
  <section>
    <div class="mx-auto overflow-visible">
      <iframe
        id="pdfV"
        style="min-height:100vh;width:100%"
      />
    </div>
  </section>
</template>
<style>

</style>
<script>
  // import utils from './utils/utils.js'
  var pdfMake = require('pdfmake/build/pdfmake.js')
  var pdfFonts = require('pdfmake/build/vfs_fonts.js')
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  export default {
    name: 'ViewCertification',
    // mixins: [utils],
    props: {
      asuulgaId: {
        type: String,
        required: false,
      },
      userId: {
        type: String,
        required: false,
      },
    },
    data: () => ({
      color: '#ccc',
    }),
    computed: {
    },
    created() {
      this._generateCrt()
    },
    methods: {
      _generateCrt() {
        var docDefinition = {
          pageSize: 'A4',
          pageOrientation: 'landscape',
          // Set page margins
          pageMargins: [0, 110, 0, 100],
          content: [
            {
              text: 'Тунгаамаа Дэлгэрцогт',
              fontSize: 32,
              alignment: 'center',
              margin: [0, 160, 0, 0],
            },

            {
              layout: 'noBorders',
              margin: [162.5, 130, 0, 0],
              table: {
                widths: [200, 85, 200],
                body: [
                  [
                    { text: 'Байгууллагын нэр', alignment: 'center' },
                    { text: new Date(), alignment: 'center' },
                  ],
                ],
              },
            },
          ],
          background: [
           
          ],
        }
        // pdfMake.createPdf(docDefinition).download('optionalName.pdf')

        pdfMake.createPdf(docDefinition).getDataUrl(function (outDoc) {
          document.getElementById('pdfV').src = outDoc
        })
      },
    },
  }
</script>
